import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ToursSearchEndpoint } from 'package-angular';
import { Lang2 } from 'package-types/src/global';

@Pipe({
  name: 'departureCityName'
})
export class DepartureCityNamePipe implements PipeTransform {
  constructor(
    private readonly toursSearch: ToursSearchEndpoint,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {}

  transform(cityId: number): Observable<string | null> {
    return this.toursSearch.getDepartureCities(this.locale as Lang2)
      .pipe(
        map(({ departureCities }) => departureCities.find(c => c.id === cityId)?.name ?? null)
      );
  }
}
