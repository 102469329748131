import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, ValidationErrors, Validator, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { validators, valueAccessor } from '../../../helpers/angular.helper';

@UntilDestroy()
@Component({
  selector: 'app-list-input',
  templateUrl: './list-input.component.html',
  styleUrls: ['./list-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessor(ListInputComponent), validators(ListInputComponent)]
})
export class ListInputComponent implements ControlValueAccessor, Validator {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() errorTip = '';
  @Input() validateEmail = false;

  readonly formArray = this.fb.array([], {
    validators: Validators.minLength(1),
    updateOn: 'blur'
  });

  onTouched = () => { };

  constructor(private readonly fb: FormBuilder) { }

  @HostListener('click')
  onHostClick() {
    this.onTouched();
  }

  addItem(value = ''): void {
    this.formArray.push(this.getFormControl(value));
  }

  removeItem(idx: number): void {
    if (this.formArray.length > 1) {
      this.formArray.removeAt(idx);
    }
  }

  registerOnChange(fn: any): void {
    fn(this.formArray.value);
    this.formArray.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(fn);
  }

  registerOnTouched(fn: () => { }): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.pristine) {
      this.formArray.markAsPristine();
    }
    if (control.untouched) {
      this.formArray.markAsUntouched();
    }

    return this.formArray.valid ? null : { invalidArray: true };
  }

  writeValue(val: unknown): void {
    if (!Array.isArray(val)) {
      return;
    }
    this.formArray.clear();
    for (const item of val) {
      this.addItem(item);
    }
  }

  private getFormControl(value = '') {
    const validators = [Validators.required];
    if (this.validateEmail) {
      validators.push(Validators.email);
    }

    return this.fb.control(value, { validators, updateOn: 'blur' });
  }
}
