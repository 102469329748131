import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthEndpoint } from './api/auth.endpoint';
import { LocalStorageService } from './local-storage.service';

import { LoginParams, User } from '../interfaces/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private authEndpoint: AuthEndpoint,
    private localStorage: LocalStorageService,
    private jwtHelper: JwtHelperService
  ) {}

  isAuthenticated(): Observable<boolean> {
    return this.localStorage.getBoToken().pipe(
      map(token => !this.jwtHelper.isTokenExpired(token)),
    );
  }

  hasPermissions(permissions: string[]): Observable<boolean> {
    return this.localStorage.getBoToken().pipe(
      map(token => this.jwtHelper.decodeToken(token) as { data?: User }),
      map(tokenData => tokenData?.data?.permissions ?? []),
      map((tokenPermissions) => {
        return tokenPermissions.includes('*') || permissions.every(p => tokenPermissions.includes(p));
      })
    );
  }

  logout(): Observable<void> {
    return this.localStorage.removeBoToken();
  }

  login(params: LoginParams): Observable<void> {
    return this.authEndpoint.login(params).pipe(
      switchMap(({ boAuthToken }) => this.localStorage.setBoToken(boAuthToken)),
    );
  }
}
