import { Pipe, PipeTransform } from '@angular/core';

import { TourOrders } from 'package-types';

@Pipe({
  name: 'ukrainianDocumentText'
})
export class UkrainianDocumentTextPipe implements PipeTransform {
  private readonly translation: Record<TourOrders.UkrainianDocumentType, string> = {
    [TourOrders.UkrainianDocumentType.Passport]: 'Паспорт',
    [TourOrders.UkrainianDocumentType.IDPassport]: 'ID Карта'
  };
  transform(value: TourOrders.UkrainianDocumentType): string {
    return this.translation[value];
  }
}
