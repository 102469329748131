import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NzButtonModule,
  NzIconModule,
  NzLayoutModule,
  NzMenuModule,
  NzNotificationModule,
  NzToolTipModule,
  NZ_I18N,
  ru_RU
} from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import ru from '@angular/common/locales/ru';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { JwtModule } from '@auth0/angular-jwt';
import { QuillModule } from 'ngx-quill';
import { environment } from 'src/environments/environment';
import { NgxMaskModule } from 'ngx-mask';

import { API_URL, PackageAngularModule } from 'package-angular';
import { Lang2 } from 'package-types';

import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { SharedModule } from './shared/shared.module';
import { InitializationService } from './services/initialization.service';
import { AppRoutingModule } from './app-routing.module';
import { ApiInterceptor } from './services/http-interceptors/api.interceptor';
import { CustomErrorHandler } from './services/custom-error-handler';
import { AuthInterceptor } from './services/http-interceptors/auth.interceptor';
import { WINDOW } from './shared/tokens/window.token';

registerLocaleData(ru);

export function initFactory(initService: InitializationService): Function {
  return () => initService.init();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    IconsProviderModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    NzMenuModule,
    NzLayoutModule,
    NzIconModule,
    NzButtonModule,
    NzToolTipModule,
    NzNotificationModule,
    PackageAngularModule,
    NgxMaskModule.forRoot(),
    JwtModule.forRoot({ config: { tokenGetter: undefined } }),
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote'],

          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],

          [{ size: ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ['link', 'image', 'video'],

          ['clean']
        ]
      }
    })
  ],
  providers: [
    InitializationService,
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: NZ_I18N, useValue: ru_RU },
    { provide: LOCALE_ID, useValue: Lang2.Ru },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: initFactory, deps: [InitializationService], multi: true },
    { provide: API_URL, useValue: environment.apiUrl },
    { provide: WINDOW, useValue: window },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
