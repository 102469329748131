import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-clock',
  template: `{{ time$ | async | date:'medium' }}`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClockComponent {
  time$: Observable<Date> = timer(0, 1000).pipe(
    map(() => new Date())
  );
}
