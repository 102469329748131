import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';

import { messages, NotificationMessage } from '../constants/error-message';

@Injectable({
  providedIn: 'root'
})
export class ErrorNotificationService {
  constructor(private notification: NzNotificationService) {}

  showCustomMessage(message: string): void {
    this.notification.error('Ошибка', message);
  }

  showBuiltInMessage(message: NotificationMessage): void {
    this.notification.error('Ошибка', messages[message]);
  }
}
