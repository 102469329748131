import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestinationComponent {
  @Input() destinationId?: number;
}
