import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';

import { LocalStorageService } from '../local-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly storage: LocalStorageService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.storage.getBoToken().pipe(
      switchMap(token => next.handle(token ? this.auth(token, req) : req))
    );
  }

  private auth(token: string, req: HttpRequest<unknown>): HttpRequest<unknown> {
    return req.clone({
      withCredentials: true,
      headers: req.headers.set('Authorization', `Bearer ${token}`)
    });
  }
}
