import { Pipe, PipeTransform } from '@angular/core';

import { Weekday } from 'package-types';

@Pipe({
  name: 'dayOfWeekName'
})
export class DayOfWeekNamePipe implements PipeTransform {
  private readonly translation: Record<Weekday, string> = {
    [Weekday.Monday]: 'Понедельник',
    [Weekday.Tuesday]: 'Вторник',
    [Weekday.Wednesday]: 'Среда',
    [Weekday.Thursday]: 'Четверг',
    [Weekday.Friday]: 'Пятница',
    [Weekday.Saturday]: 'Суббота',
    [Weekday.Sunday]: 'Воскресенье'
  };

  transform(weekday: Weekday): string {
    return this.translation[weekday];
  }
}
