import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { convertDatesInParsedJson } from '../../helpers/converter.helper';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.responseType !== 'json') {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          convertDatesInParsedJson(event.body);
        }

        return event;
      })
    );
  }
}
