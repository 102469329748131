import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ToursSearch } from 'package-types';

@Component({
  selector: 'app-flight-description',
  templateUrl: './flight-description.component.html',
  styleUrls: ['./flight-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightDescriptionComponent {
  @Input() flight?: ToursSearch.Flight;
  @Input() title = 'Рейс';
}
