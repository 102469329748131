import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

import { AuthService } from '../../../services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFormComponent {
  loginForm: FormGroup;
  isLoading$: Subject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router) {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]]
    });
  }

  submitForm(): void {
    this.isLoading$.next(true);
    this.authService.login(this.loginForm.value)
      .pipe(
        finalize(() => this.isLoading$.next(false)),
        untilDestroyed(this)
      )
      .subscribe(() => this.router.navigate(['/']));
  }
}
