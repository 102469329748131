import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  NzButtonModule,
  NzCardModule,
  NzCheckboxModule,
  NzDescriptionsModule,
  NzFormModule,
  NzIconModule,
  NzInputModule,
  NzListModule,
  NzResultModule
} from 'ng-zorro-antd';
import { ReactiveFormsModule } from '@angular/forms';

import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ClockComponent } from './components/clock/clock.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { ToursSearchQueryComponent } from './components/tours-search-query/tours-search-query.component';
import { DepartureCityComponent } from './components/departure-city/departure-city.component';
import { DepartureCityNamePipe } from './pipes/departure-city-name.pipe';
import { DestinationComponent } from './components/destination/destination.component';
import { DestinationNamePipe } from './pipes/destination-name.pipe';
import { ClientContactsComponent } from './components/client-contacts/client-contacts.component';
import { TransportTextPipe } from './pipes/transport-text.pipe';
import { FoodTextPipe } from './pipes/food-text.pipe';
import { SexTextPipe } from './pipes/sex-text.pipe';
import { UkrainianDocumentTextPipe } from './pipes/ukrainian-document-text.pipe';
import { ListInputComponent } from './components/list-input/list-input.component';
import { DayOfWeekNamePipe } from './pipes/day-of-week-name.pipe';
import { TourOperatorComponent } from './components/tour-operator/tour-operator.component';
import { TourOperatorNamePipe } from './pipes/tour-operator-name.pipe';
import { FlightDescriptionComponent } from './components/flight-description/flight-description.component';
import { OfferDescriptionComponent } from './components/offer-description/offer-description.component';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    ClockComponent,
    LoginFormComponent,
    ToursSearchQueryComponent,
    DepartureCityComponent,
    DepartureCityNamePipe,
    DestinationComponent,
    DestinationNamePipe,
    ClientContactsComponent,
    TransportTextPipe,
    FoodTextPipe,
    SexTextPipe,
    UkrainianDocumentTextPipe,
    LoginFormComponent,
    ListInputComponent,
    DayOfWeekNamePipe,
    TourOperatorComponent,
    TourOperatorNamePipe,
    FlightDescriptionComponent,
    OfferDescriptionComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NzCardModule,
    NzListModule,
    NzResultModule,
    NzButtonModule,
    NzFormModule,
    NzInputModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzDescriptionsModule,
    NzCheckboxModule,
    NzIconModule
  ],
  exports: [
    ClockComponent,
    PageNotFoundComponent,
    LoginFormComponent,
    ToursSearchQueryComponent,
    ClientContactsComponent,
    FoodTextPipe,
    TransportTextPipe,
    SexTextPipe,
    UkrainianDocumentTextPipe,
    LoginFormComponent,
    ListInputComponent,
    DayOfWeekNamePipe,
    DepartureCityComponent,
    DestinationComponent,
    TourOperatorComponent,
    FlightDescriptionComponent,
    OfferDescriptionComponent
  ]
})
export class SharedModule {}
