import { Pipe, PipeTransform } from '@angular/core';

import { ToursSearch } from 'package-types';

import Transport = ToursSearch.Transport;

@Pipe({
  name: 'transportText'
})
export class TransportTextPipe implements PipeTransform {
  private readonly translation: Record<Transport, string> = {
    [Transport.No]: 'Без транспорта',
    [Transport.Air]: 'Авиаперелет',
    [Transport.Bus]: 'Автобус',
    [Transport.Train]: 'Поезд',
    [Transport.Ship]: 'Морской',
    [Transport.TrainBus]: 'Поезд + Автобус'
  };

  transform(value: ToursSearch.Transport): string {
    return this.translation[value];
  }
}
