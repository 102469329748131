<nz-form-item *ngFor="let control of formArray.controls; let idx = index">
  <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired *ngIf="idx === 0">{{ label }}</nz-form-label>
  <nz-form-control
    [nzSm]="14"
    [nzXs]="24"
    [nzOffset]="idx === 0 ? 0 : 6"
    [nzErrorTip]="errorTip">
    <input
      class="input"
      nz-input
      [placeholder]="placeholder"
      [formControl]="$any(control)">
    <i nz-icon nzType="minus-circle-o" class="delete-button" (click)="removeItem(idx)"></i>
  </nz-form-control>
</nz-form-item>
<nz-form-item>
  <nz-form-label
    [nzSm]="6"
    [nzXs]="24"
    nzRequired
    *ngIf="formArray.controls.length === 0">
    {{ label }}
  </nz-form-label>
  <nz-form-control
    [nzSm]="14"
    [nzXs]="24"
    [nzOffset]="formArray.controls.length === 0 ? 0 : 6">
    <button nz-button nzType="dashed" class="add-button" type="button" (click)="addItem()">
      <i nz-icon nzType="plus"></i>
      Добавить
    </button>
  </nz-form-control>
</nz-form-item>
