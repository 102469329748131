import { TopCategory } from '../interfaces/category';

export enum SectionName {
  Tours = 'tours',
  Hotels = 'hotels',
  Avia = 'avia'
}

export const common: TopCategory[] = [
  // {
  //   icon: 'user',
  //   name: 'users',
  //   text: 'Пользователи',
  //   sub: [
  //     { name: 'list', text: 'Список' }
  //   ]
  // },
  {
    icon: 'usergroup-add',
    name: 'clients',
    text: 'Клієнти',
    sub: [
      { name: 'unloading', text: 'Вивантаження' },
      // { name: 'accounts', text: 'Аккаунты' },
      // { name: 'messages', text: 'Обратная связь' },
      // { name: 'feedback', text: 'Отзывы' },
      // { name: 'subscribers', text: 'Подписчики' }
    ]
  },
  {
    icon: 'copy',
    name: 'site-pages',
    text: 'Страницы сайта',
    sub: [
      { name: 'offices', text: 'Офисы' },
      { name: 'static', text: 'Статичні сторінки' },
      // { name: 'mass-media', text: 'Статьи в СМИ' }
    ]
  },
  // {
  //   icon: 'build',
  //   name: 'services',
  //   text: 'Сервисы',
  //   sub: [
  //     { name: 'mailing', text: 'Рассылка' },
  //     { name: 'promo-code', text: 'Промокод' },
  //     { name: 'url-shortener', text: 'Сокращения ссылок' }
  //   ]
  // }
];

export const tours: TopCategory[] = [
  {
    icon: 'shopping-cart',
    name: 'orders',
    text: 'Заказы',
    sub: [
      { name: 'list', text: 'Список' }
    ]
  },
  {
    icon: 'mail',
    name: 'feedback',
    text: 'Обратная связь',
    sub: [
      { name: 'tour-selection-claims', text: 'Заявки на подбор тура' },
      { name: 'site', text: 'Форма обратной связи' }
    ]
  },
  {
    icon: 'block',
    name: 'main-page-blocks',
    text: 'Блоки на главной',
    sub: [
      // { name: 'sorting', text: 'Сортировка' },
      { name: 'list', text: 'Список' },
      // { name: 'hot-tours', text: 'Блок горячих туров' }
    ]
  },
  // {
  //   icon: '',
  //   name: 'catalog',
  //   text: 'Справочник',
  //   sub: [
  //     { name: 'countries', text: 'Страны' },
  //     { name: 'resorts', text: 'Курорты' }
  //   ]
  // },
  // {
  //   icon: '',
  //   name: 'dictionary',
  //   text: 'Словарь',
  //   sub: [
  //     { name: 'declension', text: 'Склонение слов' },
  //     { name: 'tourist', text: 'Словарь туриста' },
  //     { name: 'phrases', text: 'Фразы' }
  //   ]
  // }
];

export const hotels: TopCategory[] = [
  {
    icon: '',
    name: 'landing-pages',
    text: 'Лендинги',
    sub: [
      { name: 'main', text: 'Главная страница' }
    ]
  },
  {
    icon: '',
    name: 'catalog',
    text: 'Справочник',
    sub: [
      { name: 'hotels', text: 'Отели' }
    ]
  },
  {
    icon: '',
    name: 'dictionary',
    text: 'Словарь',
    sub: [
      { name: 'ratio', text: 'Рейтинг' },
      { name: 'services', text: 'Сервисы' },
      { name: 'service-groups', text: 'Группы сервисов' },
      { name: 'food', text: 'Питание' }
    ]
  }
];

export const avia: TopCategory[] = [
  {
    icon: '',
    name: 'services',
    text: 'Сервисы',
    sub: [
      { name: 'decoder', text: 'Дешифратор маршрута GDS' }
    ]
  },
  {
    icon: '',
    name: 'seo',
    text: 'SEO',
    sub: [
      { name: 'pass-through-linking', text: 'Блок сквозной линковки' }
    ]
  }
];

export const categories: { [key in SectionName]?: TopCategory[] } = {
  [SectionName.Tours]: tours,
  // [SectionName.Hotels]: hotels,
  // [SectionName.Avia]: avia
};
