import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { LoginParams, LoginResponse } from '../../interfaces/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthEndpoint {
  private readonly basePath: string = `${environment.apiUrl}/auth`;

  constructor(private http: HttpClient) {}

  login(data: LoginParams): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${this.basePath}/boLogin`, data);
  }
}
