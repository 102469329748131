import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(private storage: StorageMap) {}

  setBoToken(token: string): Observable<void> {
    return this.storage.set('boToken', token, { type: 'string' });
  }

  getBoToken(): Observable<string | undefined> {
    return this.storage.get<string>('boToken', { type: 'string' });
  }

  removeBoToken(): Observable<void> {
    return this.storage.delete('boToken');
  }
}
