import { isGenericObject } from './type-guards.helper';

const dateJsonFormatPattern: RegExp = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;

export function convertDatesInParsedJson(input: unknown): Date | undefined {
  if (Array.isArray(input)) {
    input.forEach((val, idx) => input[idx] = convertDatesInParsedJson(val) ?? val);
  }

  if (isGenericObject(input)) {
    Object.entries(input as any).forEach(([key, val]) => {
      input[key] = convertDatesInParsedJson(val) ?? val;
    });
  }

  if (typeof input === 'string' && dateJsonFormatPattern.test(input)) {
    return new Date(input);
  }

  return undefined;
}

export function convertFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader: FileReader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new Error(`Reading file result should be base64 string. Result: ${reader.result}`));
      }
    };
    reader.onerror = error => reject(error);
    reader.readAsDataURL(file);
  });
}
