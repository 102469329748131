import { Pipe, PipeTransform } from '@angular/core';

import { ToursSearch } from 'package-types';

import Food = ToursSearch.Food;

@Pipe({
  name: 'foodText'
})
export class FoodTextPipe implements PipeTransform {
  private readonly translation: Record<Food, string> = {
    [Food.AI]: 'AI - всё включено',
    [Food.FB]: 'FB - полный пансион (завтрак, обед, ужин)',
    [Food.BB]: 'BB - только завтраки',
    [Food.HB]: 'HB - полупансион (завтрак и ужин)',
    [Food.OB]: 'OB - без питания',
    [Food.UAI]: 'Ультра всё включено'
  };

  transform(value: Food): string {
    return this.translation[value];
  }
}
