import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { forwardRef } from '@angular/core';

export function valueAccessor<T extends new (...args: any[]) => any>(component: T) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => component),
    multi: true
  };
}

export function validators<T extends new (...args: any[]) => any>(component: T) {
  return {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => component),
    multi: true
  };
}
