import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ToursSearchEndpoint } from 'package-angular';
import { Lang2 } from 'package-types/src/global';

@Pipe({
  name: 'destinationName'
})
export class DestinationNamePipe implements PipeTransform {
  constructor(
    private readonly toursSearch: ToursSearchEndpoint,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {}

  transform(destinationId: number): Observable<string | null> {
    return this.toursSearch.getSuggestions(this.locale as Lang2, destinationId.toString(10))
      .pipe(
        map(({ suggestions }) => suggestions.find(c => c.destination.id === destinationId)?.destination.fullName ?? null)
      );
  }
}
