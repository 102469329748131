<div class="login-wrapper">
  <form nz-form [formGroup]="loginForm" class="login-form" (ngSubmit)="submitForm()">
    <nz-form-item>
      <nz-form-control [nzErrorTip]="emailErrorTemplate">
        <nz-input-group nzPrefixIcon="mail">
          <input type="email" nz-input formControlName="email" placeholder="E-Mail" />
          <ng-template #emailErrorTemplate let-control>
            <ng-container *ngIf="control.hasError('required')">
              Введите e-mail
            </ng-container>
            <ng-container *ngIf="control.hasError('email')">
              Невалидный e-mail
            </ng-container>
          </ng-template>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control nzErrorTip="Введите пароль">
        <nz-input-group nzPrefixIcon="lock">
          <input type="password" nz-input formControlName="password" placeholder="Пароль" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control>
        <label nz-checkbox nzDisabled>
          <span>Запомнить меня</span>
        </label>
        <button [disabled]="!loginForm.valid" [nzLoading]="isLoading$ | async" nz-button class="login-form-button" [nzType]="'primary'">Войти</button>
        <button disabled nz-button class="login-form-button" [nzType]="'primary'">Зарегистрироваться</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</div>
