import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ToursSearch } from 'package-types';

@Component({
  selector: 'app-tours-search-query',
  templateUrl: './tours-search-query.component.html',
  styleUrls: ['./tours-search-query.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToursSearchQueryComponent {
  @Input() query?: Partial<ToursSearch.Query> = {};
}
