import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ErrorNotificationService } from './error-notification.service';
import { NotificationMessage } from '../constants/error-message';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(
    private errorNotification: ErrorNotificationService
  ) {}

  handleError(error: any) {
    if (error instanceof HttpErrorResponse) {
      this.errorNotification.showBuiltInMessage(this.handleHttpError(error.status));
    } else {
      this.errorNotification.showCustomMessage(error);
    }

    console.error(error);
  }

  private handleHttpError(status: number): NotificationMessage {
    if (status >= 400 && status < 500) {
      switch (status) {
        case 400:
          return NotificationMessage.UnknownError;
        case 401:
          return NotificationMessage.Unauthorized;
        case 403:
          return NotificationMessage.MissedPermission;
        case 404:
          return NotificationMessage.NotFound;
        case 408:
          return NotificationMessage.RequestTimeout;
        case 422:
          return NotificationMessage.UnprocessableEntity;
      }
    } else if (status >= 500) {
      return NotificationMessage.UnknownError;
    }

    return NotificationMessage.UnknownError;
  }
}
