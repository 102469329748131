import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ToursCatalogEndpoint } from 'package-angular';

@Pipe({
  name: 'tourOperatorName'
})
export class TourOperatorNamePipe implements PipeTransform {
  constructor(
    private readonly toursCatalog: ToursCatalogEndpoint,
  ) {}

  transform(orderId: number): Observable<string | null> {
    return this.toursCatalog.getTourOperator(orderId)
      .pipe(
        map(o => o.tourOperator.name),
        catchError(() => of(null)),
      );
  }
}
