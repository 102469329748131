<nz-descriptions nzTitle="Тур" *ngIf="offer">
  <nz-descriptions-item nzTitle="Идентификатор">{{ offer.offerId }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Последнее обновление">{{ offer.lastUpdate | date:'long' }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Отель" *ngIf="hotelId">
    <app-destination [destinationId]="hotelId"></app-destination>
  </nz-descriptions-item>
  <nz-descriptions-item nzTitle="Туроператор">
    <app-tour-operator [operatorId]="offer.operatorId"></app-tour-operator>
  </nz-descriptions-item>
  <nz-descriptions-item nzTitle="Отправление из">
    <app-departure-city [cityId]="offer.departureCityId"></app-departure-city>
  </nz-descriptions-item>
  <nz-descriptions-item nzTitle="Дата отправления">
    {{ offer.dateRange.from | date:'longDate' }}
  </nz-descriptions-item>
  <nz-descriptions-item nzTitle="Дата прибытия">
    {{ offer.dateRange.to | date:'longDate' }}
  </nz-descriptions-item>
  <nz-descriptions-item nzTitle="Цена">
    {{ offer.priceUAH | currency:'UAH' }}
    /
    {{ offer.price | currency:(offer.currency | uppercase):'symbol-narrow' }}
  </nz-descriptions-item>
  <nz-descriptions-item nzTitle="Транспорт">{{ offer.transport | transportText }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Опции">{{ offer.tourOptions.join(', ') }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Питание">{{ offer.food | uppercase }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Длительность тура (ночи)">{{ offer.nights }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Длительность пребывания в отеле (ночи)">{{ offer.nightsOfStay }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Комната">{{ offer.room }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Взрослые">{{ offer.tourists.adults }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Дети">{{ offer.tourists.childrenCount }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Размещение">{{ offer.tourists.accommodation }}</nz-descriptions-item>
</nz-descriptions>
