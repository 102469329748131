import { Pipe, PipeTransform } from '@angular/core';

import { Customers } from 'package-types';

@Pipe({
  name: 'sexText'
})
export class SexTextPipe implements PipeTransform {
  private readonly translation: Record<Customers.Sex, string> = {
    [Customers.Sex.Male]: 'Мужской',
    [Customers.Sex.Female]: 'Женский'
  };

  transform(value: Customers.Sex): string {
    return this.translation[value];
  }
}
