export enum NotificationMessage {
  UnknownError = 'UnknownError',
  Unauthorized = 'Unauthorized',
  NotFound = 'NotFound',
  RequestTimeout = 'RequestTimeout',
  UnprocessableEntity = 'UnprocessableEntity',
  MissedPermission = 'MissedPermission'
}

export const messages: { [key in NotificationMessage]: string } = {
  [NotificationMessage.UnknownError]: 'Что-то пошло не так',
  [NotificationMessage.Unauthorized]: 'Ошибка авторизации',
  [NotificationMessage.NotFound]: 'Запрашиваемые данные не найдены',
  [NotificationMessage.RequestTimeout]: 'Время ожидания запроса истекло',
  [NotificationMessage.UnprocessableEntity]: 'Сервер не смог обработать запрос',
  [NotificationMessage.MissedPermission]: 'Отсутствуют права доступа'
};
