import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-departure-city',
  templateUrl: './departure-city.component.html',
  styleUrls: ['./departure-city.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DepartureCityComponent {
  @Input() cityId?: number;
}
