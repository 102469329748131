<nz-descriptions nzTitle="Параметры поиска тура" *ngIf="query">
  <nz-descriptions-item nzTitle="Дата отправления">
    {{ query.dates?.checkIn | date:'longDate' }}
  </nz-descriptions-item>
  <nz-descriptions-item nzTitle="Дата прибытия">
    {{ query.dates?.checkOut | date:'longDate' }}
  </nz-descriptions-item>
  <nz-descriptions-item nzTitle="Вылет из">
    <app-departure-city [cityId]="query.departureCityId"></app-departure-city>
  </nz-descriptions-item>
  <nz-descriptions-item nzTitle="Тур в">
    <app-destination [destinationId]="query.destinationId"></app-destination>
  </nz-descriptions-item>
  <nz-descriptions-item nzTitle="Взрослые">{{ query.tourists?.adults }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Дети">{{ query.tourists?.children?.length }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Продолжительность (ночей)">
    {{ query.duration?.from }} - {{ query.duration?.to }}
  </nz-descriptions-item>
  <nz-descriptions-item nzTitle="Рейтинг отеля">
    {{ query.rating?.min || 0 }} - {{ query.rating?.max || 10 }}
  </nz-descriptions-item>
  <nz-descriptions-item nzTitle="Сервисы">{{ query.services?.join(', ')  || '-' }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Транспорт">{{ query.transports?.join(', ') || '-' }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Звёзды">{{ query.stars?.join(', ') || '-' }}</nz-descriptions-item>
  <nz-descriptions-item nzTitle="Питание">{{ query.foods?.join(', ') || '-' }}</nz-descriptions-item>
</nz-descriptions>
