import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-tour-operator',
  templateUrl: './tour-operator.component.html',
  styleUrls: ['./tour-operator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourOperatorComponent {
  @Input() operatorId?: number;
}
